import { App } from './app';

export enum Role {
  // Global
  SystemAdmin = 'SYSTEM_ADMIN',

  // FFM
  FfmDealer = 'FFM_DEALER',
  FfmFieldforceApproval = 'FFM_FIELDFORCE_APPROVAL',
  FfmFieldforceFinish = 'FFM_FIELDFORCE_FINISH',
  FfmMarketAdmin = 'FFM_MARKET_ADMIN',
  FfmSupervisor = 'FFM_SUPERVISOR',

  // Greenland
  GreenlandManager = 'GREENLAND_MANAGER',
  GreenlandStandard = 'GREENLAND_STANDARD',

  // Porsche Strategy 2030
  StrategyDealer = 'STRATEGY_DEALER',
  StrategyManager = 'STRATEGY_MANAGER',

  // Network Planning
  NetworkPlanningPag = 'NETWORK_PLANNING_PAG',
  NetworkPlanningMarket = 'NETWORK_PLANNING_MARKET',
  NetworkPlanningRegion = 'NETWORK_PLANNING_REGION',

  // Retail Design
  RetailDesignRegionalOfficeMarketWrite = 'RETAIL_DESIGN_REGIONAL_OFFICE_MARKET_WRITE',
  RetailDesignMarketRead = 'RETAIL_DESIGN_MARKET_READ',
  RetailDesignMarketWrite = 'RETAIL_DESIGN_MARKET_WRITE',
  RetailDesignPagRead = 'RETAIL_DESIGN_PAG_READ',
  RetailDesignPagWrite = 'RETAIL_DESIGN_PAG_WRITE',
  RetailDesignFormatApprovalRead = 'RETAIL_DESIGN_FORMAT_APPROVAL_READ',
  RetailDesignFormatApprovalWrite = 'RETAIL_DESIGN_FORMAT_APPROVAL_WRITE',

  // Survey
  SurveyPagAdmin = 'SURVEY_PAG_ADMIN',
  SurveyPagRead = 'SURVEY_PAG_READ',
  SurveyMarketAdmin = 'SURVEY_MARKET_ADMIN',
  SurveyMarketRead = 'SURVEY_MARKET_READ',
  SurveyWrite = 'SURVEY_WRITE',

  // Mystery Shopping
  MysteryShoppingPag = 'MYSTERY_SHOPPING_PAG',
  MysteryShoppingMarket = 'MYSTERY_SHOPPING_MARKET',
  MysteryShoppingDealer = 'MYSTERY_SHOPPING_DEALER',

  // Multisensory Experience
  MultisensoryExperiencePag = 'MULTISENSORY_EXPERIENCE_PAG',
  MultisensoryExperienceMarket = 'MULTISENSORY_EXPERIENCE_MARKET',
  MultisensoryExperienceExternal = 'MULTISENSORY_EXPERIENCE_EXTERNAL',
}

export const rolesFor = (app: App): Role[] => {
  switch (app) {
    case App.FieldForceManagement:
      return [
        Role.FfmDealer,
        Role.FfmFieldforceApproval,
        Role.FfmFieldforceFinish,
        Role.FfmMarketAdmin,
        Role.FfmSupervisor,
      ];

    case App.Greenland:
      return [Role.GreenlandManager, Role.GreenlandStandard];

    case App.PorscheStrategy2030:
      return [Role.StrategyDealer, Role.StrategyManager];

    case App.NetworkPlanning:
      return [
        Role.NetworkPlanningPag,
        Role.NetworkPlanningMarket,
        Role.NetworkPlanningRegion,
      ];

    case App.RetailDesign:
      return [
        Role.RetailDesignRegionalOfficeMarketWrite,
        Role.RetailDesignMarketRead,
        Role.RetailDesignMarketWrite,
        Role.RetailDesignPagRead,
        Role.RetailDesignPagWrite,
        Role.RetailDesignFormatApprovalRead,
        Role.RetailDesignFormatApprovalWrite,
      ];

    case App.Survey:
      return [
        Role.SurveyPagAdmin,
        Role.SurveyPagRead,
        Role.SurveyMarketAdmin,
        Role.SurveyMarketRead,
        Role.SurveyWrite,
      ];

    case App.MysteryShopping:
      return [
        Role.MysteryShoppingPag,
        Role.MysteryShoppingMarket,
        Role.MysteryShoppingDealer,
      ];

    case App.MultisensoryExperience:
      return [
        Role.MultisensoryExperiencePag,
        Role.MultisensoryExperienceMarket,
        Role.MultisensoryExperienceExternal,
      ];

    default:
      return [];
  }
};
