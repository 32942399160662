/* eslint-disable react-hooks/rules-of-hooks */
import { createFileRoute, useParams } from '@tanstack/react-router';
import { PersonInfo } from '../../../../pages';

const appToComponent = {
  'field-force-management': PersonInfo.FieldForceManagement,
  greenland: PersonInfo.Greenland,
  'porsche-strategy-2030': PersonInfo.PorscheStrategy2030,
  'network-planning': PersonInfo.NetworkPlanning,
  'retail-design': PersonInfo.RetailDesign,
  survey: PersonInfo.Survey,
  'mystery-shopping': PersonInfo.MysteryShopping,
  'multisensory-experience': PersonInfo.MultisensoryExperience,
  'pc-roadmap': PersonInfo.PcRoadmap,
};

export const Route = createFileRoute('/admin/person-info/$personId/$app')({
  component: () => {
    const { app } = useParams({ from: '/admin/person-info/$personId/$app' });

    if (app in appToComponent) {
      const Component = appToComponent[app as keyof typeof appToComponent];
      return <Component />;
    }

    return <PersonInfo.RedirectToTab />;
  },
});
