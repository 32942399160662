export enum App {
  FieldForceManagement = 'FIELD_FORCE_MANAGEMENT',
  Greenland = 'GREENLAND',
  PorscheStrategy2030 = 'PORSCHE_STRATEGY_2030',
  NetworkPlanning = 'NETWORK_PLANNING',
  RetailDesign = 'RETAIL_DESIGN',
  Survey = 'SURVEY',
  MysteryShopping = 'MYSTERY_SHOPPING',
  MultisensoryExperience = 'MULTISENSORY_EXPERIENCE',
  PcRoadmap = 'PC_ROADMAP',
}
